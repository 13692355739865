// extracted by mini-css-extract-plugin
export var author = "blog-card-module--author--b70f0";
export var authorImage = "blog-card-module--author-image--a7b22";
export var authorName = "blog-card-module--author-name--d6efc";
export var blogCategories = "blog-card-module--blog-categories--fb5da";
export var blogCategory = "blog-card-module--blog-category--7e855";
export var blogContainer = "blog-card-module--blog-container--4c69d";
export var blogDate = "blog-card-module--blog-date--42265";
export var blogDescription = "blog-card-module--blog-description--599fa";
export var blogDetails = "blog-card-module--blog-details--e28fb";
export var blogImage = "blog-card-module--blog-image--40159";
export var blogInfo = "blog-card-module--blog-info--5d682";
export var blogLink = "blog-card-module--blog-link--1b700";
export var blogPart = "blog-card-module--blog-part--71d1d";
export var blogTitle = "blog-card-module--blog-title--55ef0";
export var multipleAuthor = "blog-card-module--multiple-author--169c1";
export var title = "blog-card-module--title--7d7f8";