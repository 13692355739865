// extracted by mini-css-extract-plugin
export var aboutContent = "author-content-module--about-content--ce84f";
export var authorContainer = "author-content-module--author-container--3f4f6";
export var avatar = "author-content-module--avatar--c5d39";
export var connectWith = "author-content-module--connect-with--d6273";
export var name = "author-content-module--name--25632";
export var position = "author-content-module--position--c2eee";
export var postCards = "author-content-module--post-cards--d3e1e";
export var posts = "author-content-module--posts--95e45";
export var postsTitle = "author-content-module--posts-title--b8579";
export var sectionTitle = "author-content-module--section-title--d0d42";
export var text = "author-content-module--text--453f8";