import React from 'react';
import { graphql } from 'gatsby';

import { AuthorContent } from '@pages-impl/author/AuthorContent';
import Layout from '@src/layouts';

export default function AuthorTemplate(props) {
  const {
    author: {
      frontmatter: { name, surname, position, bio, avatar, linkedinUrl },
    },
    recentPosts,
  } = props.data;

  return (
    <Layout {...props} contactFormType={false}>
      <AuthorContent
        name={name}
        surname={surname}
        position={position}
        bio={bio}
        avatar={avatar}
        linkedinUrl={linkedinUrl}
        recentPosts={recentPosts}
      />
    </Layout>
  );
}

export const AuthorsQuery = graphql`
  query AuthorsRecentPosts($path: String, $authorId: String) {
    author: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        surname
        name
        bio
        position
        linkedinUrl
        avatar {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
    }
    recentPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          layout: { eq: "blog" }
          authors: { elemMatch: { frontmatter: { authorId: { eq: $authorId } } } }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            authors {
              frontmatter {
                surname
                name
                path
                avatar {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                  extension
                  publicURL
                }
              }
            }
            blogId
            title
            path
            date
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            shortDescription
            blogKeywords
            searchKeywords
            postCategories {
              frontmatter {
                postCategoryName
              }
            }
          }
          wordCount {
            words
          }
        }
      }
    }
  }
`;
