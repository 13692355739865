import React, { useMemo, useContext } from 'react';

import SEO from '@commons/SEO';
import { NewStandardSection } from '@commons/index';
import Image from '@commons/image/Image';
import { Link } from '@commons/link/Link';
import { parseGraphBlogPostsToArray } from '@src/utils/parsers';
import { BlogCard } from '@commons/blog-post-card/BlogCard';
import { TopBannerContext } from '@context/TopBannerContext';
import linkedinIcon from '@images/linkedin-icon.svg';

import * as styles from './author-content.module.scss';

const setSeoDescription = (bio, name, surname, position) => {
  const defaultDescription = `${name} ${surname} is ${
    position ? `a ${position} and` : 'an'
  } author on CodiLime's blog. Check out the author's articles on the blog.`;
  return bio && bio.length > 10 ? bio.slice(0, 150) + '...' : defaultDescription;
};

export const AuthorContent = ({ name, surname, position, bio, avatar, linkedinUrl, recentPosts }) => {
  const { isBannerHidden } = useContext(TopBannerContext);
  const seoTitle = name + ' ' + surname + ' | ' + position + ' - CodiLime Blog';
  const seoDescription = setSeoDescription(bio, name, surname, position);

  const recentPostsData = useMemo(() => {
    return parseGraphBlogPostsToArray(recentPosts);
  }, []);

  return (
    <>
      <SEO seoTitle={seoTitle} description={seoDescription} />
      <NewStandardSection classNames={{ section: !isBannerHidden && 'top-banner-margin' }}>
        <div className={styles.authorContainer}>
          <div>
            <p className={styles.sectionTitle}>ABOUT THE AUTHOR</p>
            <div className={styles.aboutContent}>
              <div className={styles.text}>
                <h1 className={styles.name}>{name + ' ' + surname}</h1>
                <h2 className={styles.position}>{position}</h2>
                {bio && <p>{bio}</p>}
              </div>
              <div className={styles.avatar}>
                <Image image={avatar} alt={name + ' ' + surname} />
              </div>
            </div>
          </div>
          {linkedinUrl && (
            <div className={styles.connectWith}>
              <p className={styles.sectionTitle}>CONNECT WITH {(name + ' ' + surname).toUpperCase()}</p>
              <Link href={linkedinUrl}>
                <img loading="lazy" src={linkedinIcon} alt="Linkedin" />
              </Link>
            </div>
          )}
          <div className={styles.posts}>
            <h4 className={styles.postsTitle}>
              Recent posts <span>by {name}:</span>
            </h4>
            <div className={styles.postCards}>
              {recentPostsData.map((post) => (
                <BlogCard key={post.path} {...post} />
              ))}
            </div>
          </div>
        </div>
      </NewStandardSection>
    </>
  );
};
